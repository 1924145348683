<!--访客模块--车辆通行记录(卡片记录)-->
<template>
  <div id="carThroughrecords" ref="carThroughrecords" v-loading="loading" :element-loading-text="$t('carThroughrecords.a0')">
    <!-- 头部 -->
    <div class="header">
      <!-- 页面标题 -->
      <my-headertitle>{{ $t("carThroughrecords.a1") }}</my-headertitle>
      <!-- 查询栏 -->
      <div class="query">
        <a-row :gutter="16">
          <!-- 姓名 -->
          <a-col :span="3">
            <a-input v-model="form.name" :placeholder="$t('carThroughrecords.a2')"/>
          </a-col>
          <!-- 车牌号码 -->
          <a-col :span="3">
            <a-input v-model="form.number" :placeholder="$t('carThroughrecords.a3')"/>
          </a-col>
          <!-- 日期时间 -->
          <a-col :span="10">
            <a-input-group compact>
              <!-- 初始日期 -->
              <div style=" width: 45%">
                <a-date-picker v-model="form.date1" :disabled-date="disabledStartDate" show-time :allowClear="false" format="YYYY-MM-DD HH:mm:ss" @change="handleCalendarChange" style="width:100%"/>
              </div>
              <!-- 过渡线 -->
              <div style=" width: 10%">
                <a-input style=" pointer-events: none; backgroundColor: #fff;text-align: center;padding:0px" placeholder="~"/>
              </div>
              <!-- 终止日期 -->
              <div style=" width: 45%">
                <a-date-picker v-model="form.date2" :disabled-date="disabledEndDate" show-time :allowClear="false" format="YYYY-MM-DD HH:mm:ss" style="width:100%"/>
              </div>
            </a-input-group>
          </a-col>
          <!-- 查询按钮 -->
          <a-col :span="2">
            <a-button type="primary" @click="inquire">{{ $t("carThroughrecords.a6")}}</a-button>
          </a-col>
        </a-row>
      </div>
    </div>
    <!-- 表格区域 -->
    <div class="main" ref="main">
      <a-table :columns="columns" :dataSource="data" :scroll="size" :pagination="pagination" :rowKey="(record) => record.params">
        <template slot="chaozuo" slot-scope="text, record">
          <div>
            <a-popover placement="left">
              <template slot="content">
                <a-button type="primary" style="margin-right: 15px" @click="() => carImage(record)">{{ $t("carThroughrecords.a9") }}</a-button>
              </template>
              <a>{{ $t("carThroughrecords.a11") }}</a>
            </a-popover>
          </div>
        </template>
      </a-table>
    </div>

    <!-- 车辆识别图片 -->
  <a-modal :title="$t('carThroughrecords.a9')" centered :width="800" :visible="carVisible" :closable="false" @cancel="carImageCancel" :destroyOnClose="true" v-drag>
    <template slot="footer">
      <a-button key="back" @click="carImageCancel">
        {{ $t("carThroughrecords.a13") }}
      </a-button>
    </template>
    <div v-loading="carLoading" :element-loading-text="$t('carThroughrecords.a0')" style="position:relative;">
      <a-row>
        <a-col :span="(carImageData!=null && carImageData.images!=null && carImageData.images.length==1)?24:12">
            <a-card hoverable style="width: 100%;">
                  <img
                  slot="cover"
                  alt="example"
                  src="/images/index/navigation01.png"  v-if="carImageData==null || carImageData.images==null"
                /> 
                <img
                  slot="cover"
                  alt="example"
                  :src="carImageData.images[0].image"  v-else-if="carImageData.images.length>0"
                    @click="fncOpenImg(0)"
                    :title='$t("carThroughrecords.a36")'
                />
                <a-card-meta>
                  <template slot="description">
                  <a-descriptions :title='$t("carThroughrecords.a34")'>
                    <a-descriptions-item >
                        {{$t("carThroughrecords.a2")}}:
                      <!-- {{carImageData!=null && carImageData.images.length>1?carImageData.images[1].name:carImageData.images.length>0?carImageData.images[0].name:""}} -->
                      {{carPersonName}}
                        <br>
                      {{$t("carThroughrecords.a20")}}:
                      {{carImageData!=null && carImageData.images.length>0?carImageData.images[0].plateNumber:""}}
                      <br>
                      {{$t("carThroughrecords.a35")}}:
                      {{carImageData!=null && carImageData.images.length>0?carImageData.images[0].action:""}}
                      <br>
                      {{$t("carThroughrecords.a17")}}:
                      {{carImageData!=null && carImageData.images.length>0?carImageData.images[0].time:""}}
                    </a-descriptions-item>
                  </a-descriptions>
                  </template>
                </a-card-meta>
            </a-card>
        </a-col>
        <a-col v-if="(carImageData!=null && carImageData.images!=null && carImageData.images.length==2) || (carImageData==null || carImageData.images==null)" :span="12">
            <a-card hoverable style="width: 100%;">
                <img
                  slot="cover"
                  alt="example"
                  src="/images/index/navigation01.png"   v-if="carImageData==null || carImageData.images==null || (carImageData!=null && carImageData.images.length<2)"
                /> 
                <img
                  slot="cover"
                  alt="example"
                  :src="carImageData.images[1].image"  v-else
                  @click="fncOpenImg(1)"
                    :title='$t("carThroughrecords.a36")'
                />
              
                <a-card-meta>
                  <template slot="description">
                  <a-descriptions :title='$t("carThroughrecords.a34")'>
                    <a-descriptions-item>
                        {{$t("carThroughrecords.a2")}}:
                      <!-- {{carImageData!=null && carImageData.images.length>1?carImageData.images[1].name:""}} -->
                        {{carPersonName}}
                        <br>
                      {{$t("carThroughrecords.a20")}}:
                      {{carImageData!=null && carImageData.images.length>1?carImageData.images[1].plateNumber:""}}
                      <br>
                      {{$t("carThroughrecords.a35")}}:
                      {{carImageData!=null && carImageData.images.length>1?carImageData.images[1].action:""}}
                      <br>
                      {{$t("carThroughrecords.a17")}}:
                      {{carImageData!=null && carImageData.images.length>1?carImageData.images[1].time:""}}
                    </a-descriptions-item>
                  </a-descriptions>
                  </template>
                </a-card-meta>
            </a-card> 
        </a-col>
      </a-row>
      <div class="scs"  :style="zimg==1?'':'display: none;'"  :title='$t("carThroughrecords.a37")' @click="fncHidden()" >
          <img id="imgdiv" style="width: 90%;z-index: 10000;" :src="zimgsrc">
      </div>
      
    </div>
  </a-modal>
  
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import { getCarImageRecord} from "../../api/door";
import { getPassagewayPassRecords} from "../../api/passageway";

import moment from "moment";

export default {
  name: "carThroughrecords",
  data() {
    return {
      endOpen: false,
      startDate: undefined,
      data: undefined,
      loading: false,
      size: { x: 1400, y: 240 },
      visible: false,
      value: "",
      params: "",
      disabled: false,
      annotationLoading: false,
      form: {
        name: "",
        number: "",
        date1: moment().startOf("day"),
        date2: moment().endOf("day"),
      },
      pagination: {
        total: 0, //数据总数
        pageSize: 100, //每页中显示100条数据
        showTotal: (total) => ` ${total} ${this.$t("carThroughrecords.a16")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      columns: [
        {
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          width: 60,
        },
        {
          title: this.$t("carThroughrecords.a17"),
          dataIndex: "dateTime",
          width: 180,
          ellipsis: true,
        },
        {
          title: this.$t("carThroughrecords.a18"),
          dataIndex: "carType",
          width: 110,
          ellipsis: true,
        },
        {
          title: this.$t("carThroughrecords.a35"),
          dataIndex: "carAction",
          width: 110,
          ellipsis: true,
        },
        {
          title: this.$t("carThroughrecords.a20"),
          dataIndex: "carNumber",
          width: 130,
          ellipsis: true,
        },
        {
          title: this.$t("carThroughrecords.a19"),
          dataIndex: "personName",
          width: 90,
          ellipsis: true,
        },
        {
          title: this.$t("carThroughrecords.a33"),
          dataIndex: "managementCode",
          width: 110,
          ellipsis: true,
        },
        {
          title: this.$t("information.a256"),
          dataIndex: "nationalityName",
          width: 90,
          ellipsis: true,
        },
        // {
        //   title: this.$t("carThroughrecords.a21"),
        //   dataIndex: "bodyTemperature",
        //   width: 70,
        //   ellipsis: true,
        // },
        {
          title: this.$t("carThroughrecords.a22"),
          dataIndex: "equipmentGrName",
          width: 110,
          ellipsis: true,
        },
        {
          title: this.$t("carThroughrecords.a23"),
          dataIndex: "equipmentName1",
          width: 100,
          ellipsis: true,
        },
        // {
        //   title: this.$t("carThroughrecords.a24"),
        //   dataIndex: "equipmentName2",
        //   width: 180,
        //   ellipsis: true,
        // },
        {
          title: this.$t("carThroughrecords.a25"),
          dataIndex: "siteName",
          width: 120,
          ellipsis: true,
        },
        // {
        //   title: this.$t("carThroughrecords.a26"),
        //   dataIndex: "description",
        //   width: 140,
        //   ellipsis: true,
        // },
        {
          title: this.$t("carThroughrecords.a27"),
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 150,
          fixed: "right",
        },
      ],
      carVisible:false,
      carLoading:false,
      defaultImage:"",
      carImageData:null,
      carPersonName:'',
      serviceType:12,
      zimg:0,
      zimgsrc:"/images/index/navigation01.png",
    };
  },
  mounted() {
    
    let ssType = localStorage.getItem('serviceType');
    // if('IOW'===siteType){
    //   this.serviceType = 11;
    // } else if('VST'===siteType){
    //   this.serviceType = 12;
    // } else if('ATD'===siteType){
    //   this.serviceType = 13;
    // }
    console.log("serviceType>>>>",ssType);
    this.tableSize();
    window.addEventListener("resize", this.tableSize);
  },
  activated(){
      this.data =[];
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tableSize);
  },
  methods: {
    moment,
    fncOpenImg(idx){
        this.zimgsrc = this.carImageData.images[idx].image;
        this.zimg = 1;
    },
	  fncHidden(obj){
        this.zimg = 0;
        this.zimgsrc ='/images/index/navigation01.png';
		},
    // 表格自适应方法
    tableSize() {
      let height = this.$refs.main.clientHeight;
      let tableHeight = height - 160;
      this.size.y = tableHeight;
    },
    // 条件查询方法
    inquire() {
      const date1 = this.form.date1
      const date2 = this.form.date2
      if(!date1 || !date2){
        this.form.date1 = moment().startOf("day")
        this.form.date2 = moment().endOf("day")
      }
      this.pagination.defaultCurrent = 1;
      this.pagination.pageSize = 100;
      this.loading = true;
      let data = {
        clientId: this.$store.getters.clientId,
        sitegrId: this.$store.getters.sitegrId,
        siteId: this.$route.query.id,
        tenantId: 0,
        startDate: this.form.date1.format("YYYY-MM-DD HH:mm:ss"),
        endDate: this.form.date2.format("YYYY-MM-DD HH:mm:ss"),
        personName: this.form.name,
        cardFaceNo: this.form.number,
        serviceType:this.serviceType,
        category:"6",
      };
      getPassagewayPassRecords(data)
        .then((res) => {
          // console.log(res);
          this.loading = false;
          let { data } = res.data;
          this.data = data;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    carImage(record){
      this.carVisible = true;
       console.log("carImage record>>",record);
      let param = {
        siteId:this.$route.query.id,
        params:record.params,
      };
      this.carLoading = true;
      console.log("carImage param>>",param);
      getCarImageRecord(param)
      .then((res) => {
        console.log("carImage data>>",res);
        if( res.errorCode==='00'){
          this.carImageData = res.data;
          this.carPersonName = this.carImageData!=null && this.carImageData.images.length>1?this.carImageData.images[1].name:this.carImageData.images.length>0?this.carImageData.images[0].name:"";
          if(this.carPersonName==''){
            this.carPersonName = this.carImageData.historyList[0].carType;
          }
        }else{
          this.$message.error(this.$t('carThroughrecords.a32'));
        }
        this.carLoading = false;
      })
      .catch((err) => {
        this.carLoading = false;
        console.log(err);
      });
    },
    carImageCancel(){
      this.carVisible = false;
      this.carImageData = null;
    },

    // 开始日期限制选择范围（未来日期不可选）
    disabledStartDate(startValue) {
      // 获取计算机当前日期
      const currentDateValue = moment()
      if (!startValue) { return false }
      // 禁用大于计算机日期的日期值
      return startValue > currentDateValue
    },
    // 终止日期/时间的日期不可选范围
    disabledEndDate(endValue) {
      const startDateValue = this.form.date1
      const maxDateValue = moment(startDateValue._d).add(1, "months")
      const currentDateValue = moment().endOf("day")
      if (!endValue) { return false }
      return ( startDateValue > endValue || endValue > currentDateValue || maxDateValue < endValue)
    },
    // 初始日期/时间选择变化回调
    handleCalendarChange(value, mode) {
      const currentDateValue = moment()
      const startDateValue = moment(value._d)
      const endDateValue = this.form.date2
      const maxDateValue = moment(value._d).add(1, 'months')
      // 判断初始日期时间是否空值
      if(value){
        if(endDateValue > maxDateValue){
          this.form.date2 = moment(value._d).add(1, 'months').subtract(1, 'day').endOf('day')
        }else if(startDateValue > endDateValue){
          if(currentDateValue > maxDateValue){
            this.form.date2 = moment(value._d).add(1, 'months').subtract(1, 'day').endOf('day')
          }else{
            this.form.date2 = moment().endOf('day')
          }
        }
      }
    }
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#carThroughrecords {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  height: 113px;
  padding: 0 20px;
  overflow: hidden;
}
.query{
  margin: 15px 0px;
}
.main {
  width: 100%;
  height: calc(100% - 113px);
  padding: 0px 20px;
  overflow: hidden;
}
.scs{
  width: 792px;
    /* height: 540px; */
    background-color: #000000;
    position: absolute;
    top: -20px;
    text-align: center;
    left: -20px;
    z-index: 9999;
    /* opacity: 0.3; */
    cursor: pointer;
}
</style>